import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppLayout } from '../layout/AppLayout';
import { BaseLayout } from '../layout/BaseLayout';

const Login = React.lazy(() => import('../pages/Login'));
const Clients = React.lazy(() => import('../pages/Clients'));
const Client = React.lazy(() => import('../pages/Client'));
const Team = React.lazy(() => import('../pages/Team'));
const Dealertrack = React.lazy(() => import('../pages/Dealertrack'));
const Vehicle = React.lazy(() => import('../pages/Vehicle'));
const Journey = React.lazy(() => import('../pages/Journey'));
const Daisi = React.lazy(() => import('../pages/Daisi'));
const CrmBuilds = React.lazy(() => import('../pages/CrmBuilds'));

export function AppRoutes({ toggleTheme, theme }: { toggleTheme: () => void, theme: 'light' | 'dark' }) {
  return (
    <Routes>
      <Route path="/" element={<BaseLayout />}>
        <Route path="/login" element={<Login />} />
      </Route>

      <Route path="/" element={<AppLayout toggleTheme={toggleTheme} theme={theme} />}>
        <Route index element={<Navigate to="/clients" replace />} />
        <Route path="/clients/:id/inventory/:vin" element={<Vehicle />} />
        <Route path="/clients/:id" element={<Client />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/team" element={<Team />} />
        <Route path="/dealertrack" element={<Dealertrack />} />
        <Route path="/journey" element={<Journey />} />
        <Route path="/daisi" element={<Daisi />} />
        <Route path="/crm-builds" element={<CrmBuilds />} />
      </Route>
    </Routes>
  );
}